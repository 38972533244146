<template>
  <div class="mobile-compatibility">
    <div class="row text-center no-gutters">
      <div class="col-6 col-md-2"><i class="fab fa-apple"></i></div
      ><div class="col-auto pr-1"><img class="img-fluid" :src="yesNoImg(1)" /></div
      ><div class="label col text-left d-none d-md-inline">Apple compatible</div>
    </div>
    <div class="row text-center no-gutters">
      <div class="col-6 col-md-2"><i class="fab fa-android"></i></div
      ><div class="col-auto pr-1"><img class="img-fluid" :src="yesNoImg(2)" /></div
      ><div class="label col text-left d-none d-md-inline">Android compatible</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    vehicle: Object,
  },
  methods: {
    yesNoImg(type) {
      //1: Apple, 2: Android
      const prop = type === 1 ? "Apple_CarPlay" : "Android_Autos";
      const imgName =
        this.vehicle &&
        (this.vehicle[prop] === "Yes" ||
          this.vehicle.Proprietary_Phone_Pairing === "Yes")
          ? "check"
          : "no";
      return require(`Images/${imgName}.png`);
    },
  },
};
</script>
<style lang="scss" scoped>
img {
  max-height: 13px;
  max-width: 13px;
  margin-top: -2px;
}
i {
  padding-right: 5px;
}
.label {
  color: $secondary-font-color-dark;
  font-family: $primary-font-family;
  font-weight: normal;
  font-size: 0.8rem;
}
</style>